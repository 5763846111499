<template>
  <div class="partner-form">
    <Header class="" />
    <h2 class="margin-top25"></h2>
    <b-overlay :show="show" rounded="sm">
      <form-wizard
        color="#366ce3"
        :title="$t('ValidatorForm.Register_validator')"
        :subtitle="$t('ValidatorForm.Complete_your_account')"
        shape="square"
        :finish-button-text="$t('common.Submit')"
        :next-button-text="$t('common.Next')"
        :back-button-text="$t('common.Previous')"
        class="mb-3 pt-1"
        @on-complete="formSubmitted"
      >
        <!-- Broker details tab -->
        <tab-content
          :title="$t('ValidatorForm.Partner_Details')"
          icon="feather icon-file-text"
          :before-change="validationForm"
        >
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("ValidatorForm.Partner_Details") }}</h5>
                <small class="text-muted"
                  >{{ $t("ValidatorForm.Enter_Partner_Details") }}
                </small>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.Full_Name')"
                  label-for="Fomraml_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    rules="required"
                  >
                    <b-form-input
                      id="Fomraml_name"
                      v-model="createPartnerProfile.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('ValidatorForm.Full_Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.Email')"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="createPartnerProfile.emailValue"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="john.doe@email.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.Phone_Number')"
                  label-for="Phone"
                >
                  <validation-provider name="phone" rules="required">
                    <VuePhoneNumberInput
                      v-model="phoneNumber"
                      color="#ea5455"
                      error-color="#ea5455"
                      @update="onUpdate"
                      :translations="
                        $store.state.locale.locale == 'ar'
                          ? translationsArabic
                          : translations
                      "
                    />

                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small v-if="!isValidNumber" class="text-danger">{{
                      $t("common.phone_Invalid")
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="owner_type"
                  rules="required"
                >
                  <b-form-group :state="errors.length > 0 ? false : null">
                    <label class="required" label-for="owner_type"
                      >{{ $t("ValidatorForm.Owner_Type") }}
                    </label>

                    <v-select
                      id="owner_type"
                      v-model="createPartnerProfile.ownerType"
                      :options="
                        $store.state.locale.locale == 'ar'
                          ? OwnerTypeOptionArabic
                          : OwnerTypeOption
                      "
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                v-if="createPartnerProfile.ownerType.value == 'individual'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Gender"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('ValidatorForm.Gender')"
                    label-for="Gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Gender"
                      v-model="createPartnerProfile.selectedGender"
                      :options="
                        $store.state.locale.locale == 'ar'
                          ? createPartnerProfile.GenderOptionArabic
                          : createPartnerProfile.GenderOption
                      "
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Partner Information tab -->
        <tab-content
          :title="$t('ValidatorForm.Partner_Info')"
          icon="feather icon-user"
          :before-change="validationFormInfo"
        >
          <validation-observer ref="infoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("ValidatorForm.Partner_Info") }}</h5>
                <small class="text-muted">{{
                  $t("ValidatorForm.Enter_Partner_Info")
                }}</small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.License_Details')"
                  label-for="licenseDetails"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="licenseDetails"
                    rules="required"
                  >
                    <b-form-input
                      id="licenseDetails"
                      v-model="createPartnerProfile.licenseDetails"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('ValidatorForm.License_Details')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Nationality"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('ValidatorForm.Your_nationality')"
                    label-for="Nationality"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Nationality"
                      v-model="createPartnerProfile.selectedNationality"
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('ValidatorForm.industries_services_belong')"
                    label-for="Industry"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div id="app">
                      <treeselect
                        v-model="createPartnerProfile.selectedIndustry"
                        :multiple="true"
                        :placeholder="$t('common.Select')"
                        :options="industries"
                        @input="handeTreeSelect"
                        :normalizer="
                          $store.state.locale.locale == 'ar'
                            ? normalizerArabic
                            : normalizer
                        "
                      />
                    </div>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('ValidatorForm.Country')"
                    label-for="Country"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Country"
                      multiple
                      v-model="createPartnerProfile.selectedCountry"
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.Brief_overview_yourself')"
                  label-for="Highlights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Highlights"
                    :rules="`required|max:${$maxHighlightsUser}`"
                  >
                    <b-form-textarea
                      id="Highlights"
                      v-model="createPartnerProfile.Highlights"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('ValidatorForm.Brief_overview_yourself')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.description_yourself')"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="createPartnerProfile.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('ValidatorForm.description_yourself')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.description_previous_work')"
                  label-for="previousWork"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="previousWork"
                    rules="required"
                  >
                    <b-form-textarea
                      id="previousWork"
                      v-model="createPartnerProfile.previousWork"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="
                        $t('ValidatorForm.description_previous_work')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Language/Experiences  tab-->
        <tab-content
          :title="$t('ValidatorForm.Partner_Experiences')"
          icon="feather icon-tag"
          :before-change="validationFormExperiences"
        >
          <validation-observer ref="ExperiencesRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("ValidatorForm.About_you") }}</h5>
                <small class="text-muted">{{
                  $t("ValidatorForm.Enter_Language_Experiences")
                }}</small>
              </b-col>
              <b-col md="12">
                <b-form-group label="" label-for="language">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules=""
                  >
                    <form-repeater-basic :items="items" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="" label-for="Experience">
                  <validation-provider
                    #default="{ errors }"
                    name="Experience"
                    rules=""
                  >
                    <Experience :experiences="experiences" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- social link -->
        <tab-content
          :title="$t('ValidatorForm.Social_Links')"
          icon="feather icon-link"
          :before-change="validationFormSocial"
        >
          <validation-observer ref="socialRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("ValidatorForm.Social_Links") }}</h5>
                <small class="text-muted">{{
                  $t("ValidatorForm.Enter_Social_Links")
                }}</small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.Facebook')"
                  label-for="facebook"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Facebook"
                    rules="url"
                  >
                    <b-form-input
                      id="facebook"
                      v-model="createPartnerProfile.facebookUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://facebook.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.Twitter')"
                  label-for="twitter"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Twitter"
                    rules="url"
                  >
                    <b-form-input
                      id="twitter"
                      v-model="createPartnerProfile.twitterUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://twitter.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.LinkedIn')"
                  label-for="linked-in"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="LinkedIn"
                    rules="url"
                  >
                    <b-form-input
                      id="linked-in"
                      v-model="createPartnerProfile.linkedinUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://linkedin.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('ValidatorForm.Website')"
                  label-for="Other"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Other"
                    rules="url"
                  >
                    <b-form-input
                      id="Other"
                      v-model="createPartnerProfile.OtherUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://other.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- files -->
        <tab-content
          :title="$t('ValidatorForm.Attachment')"
          icon="feather icon-file-plus"
          :before-change="validationFormFiles"
        >
          <validation-observer ref="attachmentRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("ValidatorForm.Attachment") }}</h5>
                <small class="text-muted"
                  >{{ $t("ValidatorForm.Upload_your_files") }}
                </small>
              </b-col>

              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Personal Photo"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label for="PersonalPhoto"
                      >{{ $t("ValidatorForm.Upload_Personal_Photo") }}
                    </label>
                    <b-form-file
                      id="PersonalPhoto"
                      v-model="createPartnerProfile.PersonalPhoto"
                      required
                      accept="image/jpeg, image/png, image/gif"
                      :state="errors.length > 0 ? false : null"
                      :multiple="false"
                      :placeholder="$t('common.Choose_file_drop')"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Partner Prove"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label for="Partner-Prove"
                      >{{ $t("ValidatorForm.Partner_Prove") }}
                    </label>
                    <b-form-file
                      id="Partner-Prove"
                      v-model="createPartnerProfile.PartnerProve"
                      required
                      accept="image/jpeg, image/png, image/gif"
                      :state="errors.length > 0 ? false : null"
                      :multiple="false"
                      :placeholder="$t('common.Choose_file_drop')"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="certificates"
                    :rules="`size:${$sizeFilesUser}`"
                  >
                    <label for="Partner-certificates"
                      >{{ $t("ValidatorForm.Upload_certificates") }}
                    </label>
                    <b-form-file
                      id="Partner-certificates"
                      v-model="createPartnerProfile.PartnerCertificates"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      :state="errors.length > 0 ? false : null"
                      multiple
                      :placeholder="$t('common.Choose_file_drop')"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="relevant docouments"
                    :rules="`size:${$sizeFilesUser}`"
                  >
                    <label for="relevant-Docouments"
                      >{{ $t("ValidatorForm.relevant_Docouments") }}
                    </label>
                    <b-form-file
                      id="relevant-Docouments"
                      v-model="createPartnerProfile.PartnerRelevant"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      :state="errors.length > 0 ? false : null"
                      drop-placeholder="Drop file here..."
                      multiple
                      :placeholder="$t('common.Choose_file_drop')"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="warning p-1"
                  class="warning"
                  style="color: red"
                  >{{ errorMessage }}</b-alert
                >
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <Footer class="company-form-footer" />
  </div>
</template>

<script>
import store from "@/store";
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAlert,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import Experience from "../form-repeater/ExperiencRepeater.vue";
import FormRepeaterBasic from "../form-repeater/LanguageRepeater.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
// eslint-disable-next-line import/order
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import "../../../layouts/landpage/ValidationFrom.js";

export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BAlert,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    FormRepeaterBasic,
    Experience,
    VuePhoneNumberInput,
    Treeselect,
    BFormFile,
    BOverlay,
  },
  data() {
    return {
      translations: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Exemple :",
      },
      translationsArabic: {
        countrySelectorLabel: "رمز البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الهاتف",
        example: "مثال :",
      },
      show: false,
      errorMessage: "",
      isValidNumber: null,
      phoneNumber: null,
      phoneCode: "",
      experiences: [],
      items: [],
      certificates: [],
      institute: [],
      createPartnerProfile: {
        selectedContry: "",
        name: "",
        emailValue: "",
        licenseDetails: "",
        SelectedphoneCode: "",
        Phone: "",
        ownerType: "",
        previousWork: "",
        selectedGender: "",
        selectedNationality: "",
        selectedIndustry: [],
        selectedSkills: [],
        Highlights: "",
        description: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: [],
        otherInfo: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",

        PersonalPhoto: [],
        PartnerProve: [],
        PartnerCertificates: [],
        PartnerRelevant: [],
        GenderOption: [
          { value: "male", text: "Male" },
          { value: "female", text: "Female" },
        ],
        GenderOptionArabic: [
          { value: "male", text: "ذكر" },
          { value: "female", text: "أنثى" },
        ],
      },
      OwnerTypeOption: [
        { value: "individual", text: "Individual" },
        { value: "group", text: "Group" },
      ],
      OwnerTypeOptionArabic: [
        { value: "individual", text: "فرد" },
        { value: "group", text: "مجموعة" },
      ],
      dropzoneOptionsPersonalImage: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsProof: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsCertificates: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionRelevant: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getInvestorCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      user: "auth/user",
      skills: "profile/getAllSkills",
    }),
  },
  created() {
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },

  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      createPartner: "profile/createPartner",
      fetchCountries: "country/retreiveCountries",
    }),
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.createPartnerProfile.selectedIndustry.length = 6;
      }
    },
    onUpdate(payload) {
      if (this.phoneNumber == null) {
        this.isValidNumber = true;
      } else {
        this.isValidNumber = payload.isValid;
      }
      this.phoneCode = payload.countryCode;
    },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    formSubmitted() {
      this.show = true;
      const formatIndustries = [];
      const formatLanguage = [];
      const formatExperince = [];
      const formatLocation = [];
      // if (this.createPartnerProfile.selectedIndustry) {
      //   this.createPartnerProfile.selectedIndustry.forEach((element) => {
      //     formatIndustries.push({
      //       _id: element,
      //     });
      //   });
      // }
      this.items.forEach((element) => {
        formatLanguage.push({
          name: element.name.text,
          level: element.level,
        });
      });
      this.experiences.forEach((element) => {
        formatExperince.push({
          name: element.name,
          years: element.years,
          company: element.company,
          location: element.country.text,
        });
      });
      this.createPartnerProfile.selectedCountry.forEach((element) => {
        formatLocation.push(element.value);
      });
      const PartnerData = new FormData();
      // eslint-disable-next-line no-underscore-dangle
      PartnerData.append("userId", this.user._id);
      PartnerData.append("userType", "partner");
      PartnerData.append("fullName", this.createPartnerProfile.name);
      PartnerData.append("emailAddress", this.createPartnerProfile.emailValue);
      PartnerData.append("phoneNumber", this.phoneNumber.replace(/\s/g, ""));
      PartnerData.append("phoneCode", this.phoneCode);
      PartnerData.append(
        "ownerType",
        this.createPartnerProfile.ownerType.value
      );
      if (this.createPartnerProfile.ownerType.value == "individual") {
        PartnerData.append(
          "gender",
          this.createPartnerProfile.selectedGender.value
        );
      }

      PartnerData.append(
        "nationality",
        this.createPartnerProfile.selectedNationality.value
      );
      PartnerData.append(
        "licenseDetails",
        this.createPartnerProfile.licenseDetails
      );
      PartnerData.append(
        "previousWork",
        this.createPartnerProfile.previousWork
      );
      PartnerData.append(
        "industries",
        JSON.stringify(this.createPartnerProfile.selectedIndustry)
      );
      PartnerData.append("highlights", this.createPartnerProfile.Highlights);
      PartnerData.append("description", this.createPartnerProfile.description);
      PartnerData.append("languages", JSON.stringify(formatLanguage));
      PartnerData.append("experiences", JSON.stringify(formatExperince));
      PartnerData.append("address", JSON.stringify(formatLocation));
      if (this.createPartnerProfile.facebookUrl) {
        PartnerData.append(
          "partnerFacebook",
          this.createPartnerProfile.facebookUrl
        );
      }
      if (this.createPartnerProfile.twitterUrl) {
        PartnerData.append(
          "partnerTwitter",
          this.createPartnerProfile.twitterUrl
        );
      }
      if (this.createPartnerProfile.linkedinUrl) {
        PartnerData.append(
          "partnerLinkedIn",
          this.createPartnerProfile.linkedinUrl
        );
      }
      if (this.createPartnerProfile.OtherUrl) {
        PartnerData.append(
          "partnerOtherLinks",
          this.createPartnerProfile.OtherUrl
        );
      }

      PartnerData.append(
        "personalPhoto",
        this.createPartnerProfile.PersonalPhoto
      );
      PartnerData.append(
        "partnerProves",
        this.createPartnerProfile.PartnerProve
      );

      if (this.createPartnerProfile.PartnerCertificates) {
        for (const i of Object.keys(
          this.createPartnerProfile.PartnerCertificates
        )) {
          PartnerData.append(
            "certificates",
            this.createPartnerProfile.PartnerCertificates[i]
          );
        }
      }
      if (this.createPartnerProfile.PartnerRelevant) {
        for (const i of Object.keys(
          this.createPartnerProfile.PartnerRelevant
        )) {
          PartnerData.append(
            "otherDocuments",
            this.createPartnerProfile.PartnerRelevant[i]
          );
        }
      }

      this.createPartner(PartnerData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.show = false;
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          this.show = false;
          this.errorMessage = error.response.data.error.message;
        });
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success && this.isValidNumber) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormExperiences() {
      return new Promise((resolve, reject) => {
        this.$refs.ExperiencesRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormFiles() {
      return new Promise((resolve, reject) => {
        this.$refs.attachmentRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
  },
};
</script>

<style type="text/css">
[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}

[dir] .vs__selected {
  background-color: #366ce3;
}
</style>
